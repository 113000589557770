import React from 'react'
import glamorous, { Div } from 'glamorous'
import { css } from 'glamor'

import { smallerThan, largerThan } from '../shared/media-queries'

const triangleClass = css({
	position: 'absolute',
	[smallerThan('x-small')]: {
		height: '48px',
		width: '100%',
	},
	[largerThan('x-small')]: {
		height: '88px',
		width: '100%',
	},
})
const Triangle = ({ fill, isBottom, swap, zIndex }) => {
	const points = swap
		? ['0 0', `200 ${isBottom ? 0 : 200}`, '0 200']
		: ['200 0', '200 200', `0 ${isBottom ? 0 : 200}`]
	return (
		<svg
			width="200px"
			height="200px"
			viewBox="0 0 200 200"
			className={triangleClass}
			style={{
				zIndex,
			}}
			preserveAspectRatio="none"
		>
			<g stroke="none" fill="none">
				<g fill={fill}>
					<polygon points={points.join(' ')} />
				</g>
			</g>
		</svg>
	)
}
const EarContainer = glamorous.div(
	{
		position: 'relative',
		width: '100%',
		overflow: 'hidden',
		zIndex: -1,
		[largerThan('x-small')]: {
			height: '88px',
		},
		[smallerThan('x-small')]: {
			height: '48px',
		},
	},
	({ isBottom }) => ({
		top: isBottom ? '-1px' : '1px',
	})
)

const Jumbotron = ({
	children,
	excludeTop,
	excludeBottom,
	tiltRightColor,
	tiltLeftColor,
	tiltOnTop,
}) => {
	const tiltLeftZIndex = tiltOnTop === 'tiltLeft' ? 1 : 0
	const tiltRightZIndex = tiltOnTop === 'tiltRight' ? 1 : 0
	const topEars = excludeTop ? null : (
		<EarContainer>
			<Triangle fill={tiltLeftColor} zIndex={tiltLeftZIndex} />
			<Triangle fill={tiltRightColor} zIndex={tiltRightZIndex} swap />
		</EarContainer>
	)

	const bottomEars = excludeBottom ? null : (
		<EarContainer isBottom>
			<Triangle fill={tiltLeftColor} zIndex={tiltLeftZIndex} swap isBottom />
			<Triangle fill={tiltRightColor} zIndex={tiltRightZIndex} isBottom />
		</EarContainer>
	)

	return (
		<Div zIndex={0}>
			{topEars}
			<Div
				backgroundColor={
					tiltOnTop === 'tiltLeft' ? tiltLeftColor : tiltRightColor
				}
			>
				{children}
			</Div>
			{bottomEars}
		</Div>
	)
}

export default Jumbotron
