import React from 'react'
import { Div, H1 } from 'glamorous'

import Layout from '../components/layout'
import CenteredContent from '../components/centered-content'
import Jumbotron from '../components/jumbotron'

import { lightBlue, darkBlue } from '../shared/colors'
import { smallerThan, largerThan } from '../shared/media-queries'

const TermsPage = () => {
	return (
		<Layout title="Terms and conditions">
			<Jumbotron
				excludeTop
				tiltRightColor={lightBlue}
				tiltLeftColor={darkBlue}
				tiltOnTop="tiltLeft"
			>
				<CenteredContent>
					<Div
						color="#fff"
						display="flex"
						flexDirection="column"
						alignItems="center"
						css={{
							[smallerThan('x-small')]: {
								paddingTop: '50px',
								paddingBottom: '30px',
							},
							[largerThan('x-small')]: {
								paddingTop: '90px',
								paddingBottom: '70px',
							},
						}}
					>
						<H1
							margin={0}
							css={{
								[smallerThan('x-small')]: {
									fontSize: '36px',
								},
								[largerThan('x-small')]: {
									fontSize: '56px',
								},
							}}
						>
							Terms & conditions
						</H1>
					</Div>
				</CenteredContent>
			</Jumbotron>

			<Div
				css={{
					[smallerThan('x-small')]: {
						height: '10px',
					},
					[largerThan('x-small')]: {
						height: '20px',
					},
				}}
			/>

			<CenteredContent maxWidth={1068}>
				<b>
					Please be advised that we have updated our general terms and
					conditions effective February 12, 2020. Our new general terms and
					conditions may be found{' '}
					<a
						href="https://www.autodesk.com/company/terms-of-use/en/general-terms"
						target="_blank"
					>
						here
					</a>{' '}
					and will affect new accounts, new subscriptions, subscription renewals
					beginning on February 12, 2020, and all free and trial users who
					continue to use BuildingConnected’s Offerings on or after February 12,
					2020.
				</b>
				<br />
				<br />
				<b>
					For subscriptions or renewals purchased prior to February 12, 2020,
					the following legacy terms will apply:
				</b>
				<h3>Legacy BuildingConnected Terms and Conditions</h3>
				<p>
					<span>
						Welcome to buildingconnected.com. The buildingconnected.com website
						(the "Site") is comprised of various web pages operated by
						BuildingConnected, Inc. ("BuildingConnected", “we”, “us” or “our”).
						The Site and all of its features and services (collectively, the
						“Services”) are offered to you conditioned on your acceptance
						without modification of the terms, conditions, and notices contained
						herein (the "Terms"). Your use of the Site and the Services
						constitutes your agreement to all such Terms.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Please read these Terms carefully, and keep a copy of them for your
						reference. If you do not agree with these Terms, you should not use
						the Site or any of its Services. If you are using this Site on
						behalf of your employer or a legal entity, you represent and warrant
						that you are authorized to bind your employer or that legal entity
						to these Terms. In addition, these Terms apply to and are binding on
						any user that is authorized by said employer or legal entity to
						access or use the Services.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>a. General Description</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Buildingconnected.com is a construction communications platform that
						allows users to communicate and share documents and information
						relating to their construction projects. We offer a variety of
						Services to general contractors, subcontractors, project owners and
						other entities, including, among other things, the ability to
						communicate with your coworkers as well as professionals at other
						businesses, and performance analytics on a variety of business
						functions and interactions. You can order these Services through the
						registration process described in Section b. below.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>b. Registration and Use of the Services</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						You may browse and access certain areas of our Site without
						registration. However, you are required to register prior to using
						certain Services, such as our bid management platform or Opportunity
						Manager. Each registered user must provide complete, accurate and
						truthful information in connection with such registration and agrees
						to update such information promptly if there are any changes in the
						registration information. If you are a registered user of this Site,
						you are responsible for maintaining the confidentiality of your
						account and password, and you agree to accept responsibility for all
						activities that occur under your account or password. You may not
						assign or otherwise transfer your account to any other person or
						entity without our prior written permission. You acknowledge that
						BuildingConnected is not responsible for third party access to your
						account that results from theft or misappropriation of your account
						id or password.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Services may be provided free of charge or for a fee. If there is a
						fee to be charged for a Service (a “Paid Service”), such fee will be
						disclosed to you prior to registering for the Service and charged in
						accordance with the applicable fee for that Service as outlined in
						the applicable Sales Order Form.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						For users who have purchased a Paid Service, You agree to pay all
						applicable fees related to your purchase of the Paid Service. We may
						suspend or terminate your account or access to our Services, and
						this Site if your payment is late or your offered payment method
						cannot be processed. Contracts for Paid Services, as outlined in the
						applicable Sales Order Form, are non-cancellable and non-refundable.
						If you have questions regarding a Paid Service, please contact our
						Sales department.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>c. Use of the Services</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						You are granted a limited, non-exclusive and non-transferable
						license (without the right to sublicense) to access and use the Site
						and Services strictly in accordance with these Terms and any
						applicable policies posted on the Site, and solely for your internal
						business purposes. This means the license is personal to you and
						cannot be transferred to anyone else without our prior written
						permission. Except as set forth in these Terms, BuildingConnected
						reserves the right to refuse or cancel access to the Services,
						terminate accounts, or remove or edit content from the Site or
						Services at any time in our sole discretion.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						For users of our Paid Services (Bid Board Pro service (“Bid Board
						Pro Service”) and/or BuildingConnected Pro service
						(“BuildingConnected Pro Service”)), BuildingConnected reserves the
						right to terminate your access to the Site and any Services or any
						portion thereof at any time due to a material breach on your part of
						these Terms, including, without limitation, your failure to timely
						pay for access to the Bid Board Pro Service or BuildingConnected Pro
						Service. You will have 5 business days to cure any such breach of
						these Terms; if such breach is not cured after 5 business days,
						BuildingConnected may cancel access to all Services, terminate your
						account, or remove your content from the Site or Services.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						BuildingConnected shall have no liability or responsibility to any
						user related to or arising out of any termination of access to the
						Site and any Services.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Users of the Site and Services must be at least eighteen (18) years
						of age.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						As a condition of your use of the Site and Services, you warrant to
						BuildingConnected that you will not use the Site or Services for any
						purpose that is unlawful or prohibited by these Terms or in any
						manner which could damage, disable, overburden, or impair the Site
						or interfere with any other party's use and enjoyment of the Site or
						for any Prohibited Activities as defined in Section j below.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						You may not access the Site or the Services for purposes of
						reposting or reproducing either Our Content (as defined below) or
						any Project Content (as defined below) on any third-party website.
						You may not use any “deep-link,” “page-scrape,” “robot,” “spider” or
						other automatic device, program, algorithm or methodology, or any
						similar or equivalent manual process, to reverse engineer,
						decompile, disassemble, access, acquire, copy or monitor any portion
						of the Site or any of Our Content or any Project Content, object
						code, source code or algorithms, or in any way reproduce or
						circumvent the navigational structure or presentation of the Site or
						any of Our Content, to obtain or attempt to obtain any materials,
						documents or information through any means not purposely made
						available through the Site. You also agree that you will not (i)
						modify, translate, or create derivative works based on any element
						of the Site or Services or any related documentation; (ii) rent,
						lease, distribute, sell, resell, assign, or otherwise transfer your
						rights to use the Site or Services; (iii) use the Site or Services
						for timesharing or service bureau purposes or otherwise for the
						benefit of any person or entity other than for the benefit of user;
						or (iv) remove any proprietary notices from the Site or Services.
						You may not attempt to gain unauthorized access to any portion or
						feature of the Site, or any other systems or networks connected to
						the Site or to any BuildingConnected server, or to any of the
						services offered on or through the Site, by hacking, password
						“mining” or any other illegitimate means.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>d. Project Content</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						The Site and the Services allow owners, general contractors,
						subcontractors and other users to upload project-related
						information, such as specifications, text, photos, diagrams,
						drawings, artwork, documents, videos, written comments, feedback and
						other content, including Bid Data (“Project Content”) for various
						purposes, including posting Requests for Proposals, accepting
						responses to Request for Proposals, and responding to Requests for
						Proposals. You are solely responsible for the Project Content that
						you submit and we have no obligation to verify or confirm the
						Project Content. Also, BuildingConnected is not acting as an agent
						or representative for any party either submitting or responding to a
						Request for Proposal or otherwise using the Services. For that
						reason, we cannot and do not make any representation or warranty
						regarding any Request for Proposal or other Project Content and we
						have no responsibility whatsoever for any errors or inaccuracies in
						any Request for Proposal or response thereto or in any other Project
						Content.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						You grant to BuildingConnected and its affiliated companies and
						sublicensees a non-exclusive, perpetual, irrevocable, royalty-free,
						and fully transferable worldwide license to use the Project Content
						for purposes of providing the Services. This includes the
						following:(i) reproducing, processing, collecting and storing the
						Project Content on systems we or our third party service provider
						use to provide the Services to you; (ii)adapting and modifying the
						Project Content as necessary to fit particular formatting and other
						technical specifications for our Site and Services and for
						evaluating and improving our Services; (iii)displaying, performing
						and distributing the Project Content to third parties who are
						accessing the Site and Services to submit or respond to a Request
						for Proposal or to communicate with you; and (iv)performing data and
						other analytics based on the Project Content.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						These licenses are necessary for us to provide the Services.
						However, please note that you own all of the Project Content that
						you submit to our Site or Services. We also keep your Project
						Content confidential and do not disclose it to any third parties,
						except as follows:
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						We disclose the Project Content to our employees, independent
						contractors and third party service providers that assist us in
						providing Services to you. Such employees and third parties are
						obligated to maintain the confidentiality of the Project Content.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						We disclose the Project Content to other third parties authorized by
						you to access the Project Content. For example, if you are
						soliciting responses to a Request for Proposal, your Request for
						Proposal may be accessed by subcontractors that are invited to
						provide responses. If you are a subcontractor, your responses to a
						Request for Proposal will be made accessible to the general
						contractor that issued the Request for Proposal.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						We also perform certain data analysis on overall Project Content
						that is submitted to us. We may use and disclose such data analytics
						on an aggregate and anonymous basis to third parties or publicly,
						but we will not disclose your specific Project Content or any
						analytics that specifically identify your projects except to you as
						part of the Services or upon your consent.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>e. Bid Board™</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Our Bid Board Pro Service allows subcontractors to upload, track,
						and respond to their bid opportunities. Users will be able to
						forward bid invitation emails that they receive from other systems
						to BuildingConnected for purposes of uploading the data from the
						email as well as data from links in those emails (collectively, “Bid
						Data”).
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Users of all our Bid Board services represent and warrant that: (a)
						they have the legal right, power and authority to provide the bid
						invitation emails and Bid Data to BuildingConnected for collection,
						processing, and storage; and (b) BuildingConnected’s use of the Bid
						Data and access to the links in the bid invitation emails does not
						and will not violate any rights of any third party or any terms of
						use of any third party website, software, or product.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>f. Public Postings</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Certain features or areas of the Site may permit users to create
						public profiles or to publicly post information, photos, videos,
						text and other materials (“Publicly Posted Content”). Please note
						that Publicly Posted Content is not considered confidential and is
						available to any person that accesses the Site or Services. You
						retain ownership of your Publicly Posted Content; however, by
						posting, uploading, inputting, providing or submitting your Publicly
						Posted Content to the Site or Services, you are granting
						BuildingConnected, its affiliated companies and sublicensees the
						worldwide, irrevocable, royalty free, fully transferable and
						perpetual right to use your Publicly Posted Content for any purposes
						related to their businesses and services, including, without
						limitation, the right to: copy, distribute, transmit, publicly
						display, publicly perform, reproduce, edit, translate and reformat
						your Publicly Posted Content; and to publish your name in connection
						with your Publicly Posted Content, in any and all media or formats,
						whether now known or hereafter developed. No compensation will be
						paid with respect to the use of your Publicly Posted Content.
						BuildingConnected is under no obligation to post or use any Publicly
						Posted Content you may provide and may remove any Publicly Posted
						Content at any time in BuildingConnected's sole discretion.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>g. Privacy</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						BuildingConnected respects the privacy of its users. Its information
						privacy and security practices are set forth in its privacy policy.
						You should read the privacy policy before using the Site or Services
						or providing any personal information to BuildingConnected. Your use
						of the Site and the Services constitutes your acceptance of the
						privacy policy.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>h. Links to Third Party Sites and Services</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						The Site may contain links to other websites ("Linked Sites"). The
						Linked Sites are not under the control of BuildingConnected and
						BuildingConnected is not responsible for the contents of any Linked
						Site, including without limitation any link contained in a Linked
						Site, or any changes or updates to a Linked Site. BuildingConnected
						is providing these links to you only as a convenience, and the
						inclusion of any link does not imply endorsement by
						BuildingConnected of the Linked Site or any association with its
						operators.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Certain Services made available via the Site are delivered by third
						party sites and organizations. By using the product, service or
						functionality of a Service provided by a third party originating
						from the Site, you hereby acknowledge and consent that
						BuildingConnected may share your information (including Project
						Content and Publicly Posted Content) and data with the third party
						with whom BuildingConnected has a contractual relationship to
						provide the requested product, service or functionality on behalf of
						buildingconnected.com users and customers. Such third parties are
						required to maintain the confidentiality of Your Project Content and
						to only use such Project Content for purposes of providing the
						Services.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>i. Our Intellectual Property Rights</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						You own your Project Content, Publicly Posted Content and Your
						Material (as that term is defined below). All other content included
						on or as part of the Site or its Services, such as text, graphics,
						logos, images, trademarks, as well as the compilation thereof, and
						any software or software platforms provided by or used on the Site
						(collectively “Our Content”), is the property of BuildingConnected
						or its suppliers and protected by copyright, trademark, and other
						intellectual property laws. You agree to observe and abide by all
						copyright, trademark, and other proprietary notices, legends or
						other restrictions contained in Our Content, and will not make any
						changes thereto.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						You will not modify, publish, transmit, reverse engineer,
						participate in the transfer or sale, or create derivative works of
						the Site, the Services or Our Content. You agree that you do not
						acquire any ownership rights in any of the Site, the Services or Our
						Content. We do not grant you any licenses, express or implied, to
						the intellectual property of BuildingConnected or our licensors
						except as expressly authorized by these Terms. All rights not
						expressly granted in these Terms are reserved to BuildingConnected.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>j. Prohibited Activities</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Users of the Site and the Services must at all times comply with all
						applicable laws, rules and regulations in using the Site or
						Services. You agree not to engage in any of the following activities
						in using our Site or the Services (“Prohibited Activities”): defame,
						abuse, harass, stalk, threaten or otherwise violate the legal rights
						(such as rights of privacy and publicity) of others; publish, post,
						upload, distribute or disseminate any inappropriate, profane,
						defamatory, infringing, obscene, indecent or unlawful topic, name,
						material or information; upload files that contain software or other
						material protected by intellectual property laws (or by rights of
						privacy of publicity) unless you own or control the rights thereto
						or have received all necessary consents; upload files that contain
						viruses, corrupted files, or any other similar software or programs
						that may damage the operation of another's computer; advertise or
						offer to sell or buy any goods or services for any business purpose,
						unless the applicable Service specifically allows such messages;
						conduct or forward surveys, contests, pyramid schemes or chain
						letters; download any file posted by another user of a Service that
						you know, or reasonably should know, cannot be legally distributed
						in such manner; falsify or delete any author attributions, legal or
						other proper notices or proprietary designations or labels of the
						origin or source of software or other material contained in a file
						that is uploaded, restrict or inhibit any other user from using and
						enjoying the Services; violate any code of conduct or other
						guidelines which may be applicable for any particular Service; send
						spam, unsolicited commercial or bulk emails; harvest or otherwise
						collect information about others, including e-mail addresses,
						without their consent; or violate any applicable laws or
						regulations.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						BuildingConnected has no obligation to monitor the Services.
						However, BuildingConnected reserves the right to review materials
						posted to the Site or a Service and to remove any materials in its
						sole discretion that constitute Prohibited Activities or that
						otherwise violate these Terms. BuildingConnected reserves the right
						at all times to disclose any information as necessary to satisfy any
						applicable law, regulation, legal process or governmental request,
						or to edit, refuse to post or to remove any information or
						materials, in whole or in part, in BuildingConnected's sole
						discretion, provided that we will use reasonable efforts to notify
						you in advance of any disclosure that involves your Project Content.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Materials uploaded to the Services may be subject to posted
						limitations on usage, file size, reproduction and/or dissemination.
						You are responsible for adhering to such limitations if you upload
						the materials.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						k. Your Responsibility for Your Material and Use of the Site
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						You are solely responsible for any Project Content, Publicly Posted
						Content, Bid Data (if you use the OM Services), or any other
						material (collectively “Your Material”) you post, upload, input,
						provide or submit to the Site or the Services. As a condition to
						using the Site and Services, you represent that (a) you own or
						otherwise control all of the rights to Your Material necessary for
						you to provide, post, upload, input or submit Your Material to the
						Site or the Services; (b) Your Material does not violate or infringe
						any copyright, patent, trademark, trade secret, publicity, privacy
						or other right of any person or entity; and (c) your Material does
						not violate these Terms or any applicable law, rule, regulation or
						order of any governmental entity.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>l. Indemnification</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						You agree to defend, indemnify and hold harmless BuildingConnected,
						its officers, directors, employees, agents and third parties, for
						any losses, costs, liabilities and expenses (including reasonable
						attorneys' fees) relating to or arising out of Your Material, your
						use of or inability to use the Site or Services, your violation of
						the Terms and any representations and warranties in the Terms, your
						violation of any rights of a third party, the security of Your
						Material while in transit to us, or your violation of any applicable
						laws, rules or regulations. This means, for example, that if we are
						sued or receive a claim that Your Material infringes someone else’s
						intellectual property rights or violates confidentiality
						obligations, you will be responsible for the costs (including
						attorney’s fees) of responding to and defending that claim and for
						any settlement payments or awards that are issued. BuildingConnected
						reserves the right, at its own cost, to assume the exclusive defense
						and control of any matter otherwise subject to indemnification by
						you, in which event you will fully cooperate with BuildingConnected
						in asserting any available defenses.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>m. Copyright Claims</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						If you believe that any content or material (including, but not
						limited to, any Project Content or Publicly Posted Content) on our
						Site or Services violates your copyright, notify our copyright agent
						in writing at the address indicated below. In order for us to take
						action, you must provide the following information in your notice
						(“DMCA Takedown Notice”):
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>(i) provide your physical or electronic signature;</span>
				</p>
				<p>
					<span>
						(ii) identify the copyrighted work that you believe is being
						infringed;
					</span>
				</p>
				<p>
					<span>
						(iii) identify the item on our Site that you think is infringing
						your work and include sufficient information about where the
						material is located on our Site (including which subdomain or
						website) so that we can find it;
					</span>
				</p>
				<p>
					<span>
						(iv) provide us with a way to contact you, such as your address,
						telephone number, or e-mail;
					</span>
				</p>
				<p>
					<span>
						(v) provide a statement that you believe in good faith that the item
						you have identified as infringing is not authorized by the copyright
						owner, its agent, or the law to be used on our Site; and (vi)
						provide a statement that the information you provide in your notice
						is accurate, and that (under penalty of perjury), you are authorized
						to act on behalf of the copyright owner whose work is being
						infringed.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Any DMCA Takedown Notices should be sent to the following address or
						email:
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					BuildingConnected
					<br />
					925 Mission St.
					<br />
					San Francisco, CA 94105
					<br />
					support@buildingconnected.com
					<br />
				</p>
				<p id="h.gjdgxs" />
				<p>
					<span>Submitting a DMCA Counter-Notification</span>
				</p>
				<p />
				<p>
					<span>
						BuildingConnected will notify you that it has removed or disabled
						access to copyright-protected material that you provided, if such
						removal is pursuant to a validly received DMCA Takedown Notice. In
						response, you may provide BuildingConnected’s agent with a written
						counter-notification that includes the following information:
					</span>
				</p>
				<p />
				<p>
					<span>1. Your physical or electronic signature;</span>
				</p>
				<p />
				<p>
					<span>
						2. Identification of the material that has been removed or to which
						access has been disabled, and the location at which the material
						appeared before it was removed or access to it was disabled;
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						3. A statement from you under the penalty of perjury, that You have
						a good faith belief that the material was removed or disabled as a
						result of a mistake or misidentification of the material to be
						removed or disabled; and
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						4. Your name, physical address and telephone number, and a statement
						that you consent to the jurisdiction of a court for the judicial
						district in which your physical address is located, or if your
						physical address is outside of the United States, for any judicial
						district in which BuildingConnected may be located, and that you
						will accept service of process from the person who provided
						notification of allegedly infringing material or an agent of such
						person.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>Termination of Repeat Infringers</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						BuildingConnected reserves the right, in its sole discretion, to
						terminate the account or access of any user of this Site who is the
						subject of repeated DMCA Takedown Notices or other infringement
						notifications.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>n. Third Party Accounts</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						You will be able to connect your BuildingConnected account to
						certain third party accounts. By connecting your BuildingConnected
						account to your third party account, you acknowledge and agree that
						you are consenting to the continuous release of information about
						you to others (in accordance with your privacy settings on the third
						party site on which the third party account is hosted). If you do
						not want information about you to be shared in this manner, do not
						use this feature.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>o. Feedback</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Any submissions by you to us regarding our Site or Services (e.g.,
						comments, questions, suggestions, materials – collectively,
						“Feedback”) through any communication whatsoever (e.g., call, fax,
						email) will be treated as both non-confidential and non-proprietary.
						You hereby assign all right, title, and interest in, and we are free
						to use, without any attribution or compensation to you, any ideas,
						know-how, concepts, techniques, or other intellectual property and
						proprietary rights contained in the Feedback, whether or not
						patentable, for any purpose whatsoever, including but not limited
						to, developing, manufacturing, having manufactured, licensing,
						marketing, and selling, directly or indirectly, products and
						services using such Feedback. You understand and agree that we are
						not obligated to use, display, reproduce, or distribute any such
						ideas, know-how, concepts, or techniques contained in the Feedback,
						and you have no right to compel such use, display, reproduction, or
						distribution.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>p. International Users</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						The Site and its Service are controlled, operated and administered
						by BuildingConnected from our offices within the United States. If
						you access the Site or the Services from a location outside the
						United States, you are responsible for compliance with all export
						laws and all local laws of the jurisdiction from which you are
						accessing them. You agree that you will not use the
						BuildingConnected content or Services accessed through the Site in
						any country where such use is prohibited or in any manner prohibited
						by any applicable laws, restrictions or regulations.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>q. Liability Disclaimer</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						YOU ACKNOWLEDGE THAT YOU ARE USING THE SERVICES AT YOUR OWN RISK.
						BUILDINGCONNECTED AND ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT
						THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY
						OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
						GRAPHICS CONTAINED ON THE SITE OR THE SERVICES FOR ANY PURPOSE. TO
						THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
						INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE
						PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND.
						BUILDINGCONNECTED HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS
						WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
						RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
						MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
						NON-INFRINGEMENT.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						WITHOUT LIMITATION, BUILDINGCONNECTED DOES NOT WARRANT THE ACCURACY
						OR COMPLETENESS OF ANY INFORMATION INCLUDED IN YOUR MATERIAL OR
						OTHER INFORMATION CREATED OR PROVIDED THROUGH THE SERVICE,
						INCLUDING, WITHOUT LIMITATION, ANY INFORMATION OR DATA GENERATED OR
						POPULATED BY THE SITE OR THE SERVICES. YOU ARE SOLELY RESPONSIBLE
						FOR ENSURING THE ACCURACY OF ALL OF YOUR MATERIAL PROVIDED TO OR
						POSTED ON BUILDINGCONNECTED, INCLUDING BUT NOT LIMITED TO PROJECT
						CONTENT AND BID DATA, AND BUILDINGCONNECTED SHALL UNDERTAKE NO
						RESPONSIBILITY FOR DAMAGES CAUSED BY THE INCLUSION OF ERRONEOUS,
						INCOMPLETE OR OUTDATED INFORMATION IN SUCH MATERIALS. YOU AGREE THAT
						BUILDINGCONNECTED IS IN NO WAY RESPONSIBLE FOR THE ACCURACY,
						TIMELINESS, OR COMPLETENESS OF INFORMATION IT MAY OBTAIN FROM THIRD
						PARTIES.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
						BUILDINGCONNECTED BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
						INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER
						INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR
						PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
						PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE
						OR SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR
						ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
						OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF
						THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
						LIABILITY OR OTHERWISE, EVEN IF BUILDINGCONNECTED OR ANY OF ITS
						SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. IN NO CASE
						SHALL BUILDINGCONNECTED’S LIABILITY EXCEED THE AMOUNT OF YOUR LAST
						SUBSCRIPTION PAYMENT. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW
						THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
						INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF
						YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF
						THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
						THE SITE OR THE SERVICES.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>r. Termination/access restriction</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Except as set forth in these Terms, BuildingConnected reserves the
						right, in its sole discretion, to terminate your access to the Site
						and the standard version of any Services or any portion thereof at
						any time, with or without notice.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Your contract to use the Bid Board Pro Service and/or
						BuildingConnected Pro Service will expire on the Renewal Date set
						forth in your applicable Sales Order Form, but may be renewed by
						contacting BuildingConnected. &nbsp;BuildingConnected reserves the
						right to terminate your access to Bid Board Pro Service,
						BuildingConnected Pro Service and/or any portion thereof at any time
						due to a material breach on your part of these Terms, including,
						without limitation, your failure to timely pay for access to the OM
						Services or BuildingConnected Pro Services. You will have 5 business
						days to cure any such breach of these Terms; if such breach is not
						cured after 5 business days, BuildingConnected may cancel access to
						all Services, terminate your account, or remove your content from
						the Site or Services.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Contracts for Paid Services are non-cancellable and non-refundable.
						&nbsp;Once you have contracted to use the BuildingConnected Pro
						Service, you may not revert to the standard version of the
						BuildingConnected service upon expiration of a BuildingConnected Pro
						Service contract. &nbsp;If you do not elect to renew your
						BuildingConnected Pro Service contract you must cease using all
						version of BuildingConnected.{' '}
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						BuildingConnected shall have no liability or responsibility to any
						user related to or arising out of any termination of access to the
						Site and any Services.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>s. Electronic Communications</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						We communicate with all users of the Site electronically such as via
						email to the email address provided in their account or by posting
						notices on the Site. If you register to use the Site or the
						Services, you consent to receiving such electronic communications
						and you agree that all agreements, notices, disclosures and other
						communications that we provide to you electronically, via email and
						on the Site, satisfy any legal requirement that such communications
						be in writing.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>t. Governing Law/Dispute Resolution</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						These Terms are governed by laws of the state of California, without
						respect to its conflict of laws principles. The sole jurisdiction
						and venue for any claim arising from the Site and these Terms shall
						be the state and federal courts located in San Francisco, California
						and each party hereby consents to the exclusive jurisdiction and
						venue of such courts. YOU AGREE THAT if you want to sue us, you must
						file your lawsuit within ONE year after the event that gave rise to
						your lawsuit. OTHERWISE, your lawsuit will be PERMANENTLY BARRED.
						Use of the Site is unauthorized in any jurisdiction that does not
						give effect to all provisions of these Terms, including, without
						limitation, this section. If a proceeding is commenced to resolve
						any dispute that arises between the parties with respect to the
						matters covered by these Terms, including, without limitation, your
						obligation to timely pay BuildingConnected for access to and use of
						the Site and the Services, the prevailing party in that proceeding
						is entitled to receive its reasonable attorneys’ fees, expert
						witness fees and out-of-pocket costs, in addition to any other
						relief to which that prevailing party may be entitled.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>u. General</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						You agree that no joint venture, partnership, employment, or agency
						relationship exists between you and BuildingConnected as a result of
						these Terms or use of the Site. BuildingConnected's performance of
						these Terms is subject to existing laws and legal process, and
						nothing contained in these Terms is in derogation of
						BuildingConnected's right to comply with governmental, court and law
						enforcement requests or requirements relating to your use of the
						Site or information provided to or gathered by BuildingConnected
						with respect to such use. If any part of these Terms is determined
						to be invalid or unenforceable pursuant to applicable law including,
						but not limited to, the warranty disclaimers and liability
						limitations set forth above, then the invalid or unenforceable
						provision will be deemed superseded by a valid, enforceable
						provision that most closely matches the intent of the original
						provision and the remainder of these Terms shall continue in effect.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						Unless otherwise specified herein, these Terms constitute the entire
						agreement between the user and BuildingConnected with respect to the
						Site and it supersedes all prior or contemporaneous communications
						and proposals, whether electronic, oral or written, between the user
						and BuildingConnected with respect to the Site and the Services. A
						printed version of these Terms and of any notice given in electronic
						form shall be admissible in judicial or administrative proceedings
						based upon or relating to this agreement to the same extent an d
						subject to the same conditions as other business documents and
						records originally generated and maintained in printed form. It is
						the express wish to the parties that these Terms and all related
						documents be written in English.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>v. Changes to Site, Services and Terms</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						BuildingConnected reserves the right, in its sole discretion, and at
						any time, to change or alter the Site or Services, or discontinue
						any Services or features of the Site, and change or amend the Terms
						under which the Site and the Services are offered (collectively, the
						“Changes”). We will endeavor to post a notice on the Site of any
						material Changes and notify registered users of any such material
						Changes to the email address provided in their account. Your
						continued use of the Site and Services after such Changes are posted
						constitutes your acceptance of such Changes. Your sole remedy if you
						don’t agree with the Changes is to discontinue use of the Services.
						The most current version of the Terms will supersede all previous
						versions. BuildingConnected encourages you to periodically review
						the Terms to stay informed of our updates.
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>w. Contact Us</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					<span>
						BuildingConnected welcomes your questions or comments regarding the
						Terms, which can be sent to the following:
					</span>
				</p>
				<p>
					<span />
				</p>
				<p>
					BuildingConnected
					<br />
					925 Mission St.
					<br />
					San Francisco, CA 94105
					<br />
					support@buildingconnected.com
					<br />
				</p>
				<p>
					<span />
				</p>
				<p>
					<span />
				</p>
			</CenteredContent>
		</Layout>
	)
}

export default TermsPage
